.itqEntrance{
  display:grid;
  grid-template-columns: 302px 101px 1fr;
  justify-self: center;
  align-self: center;
  align-items: center;
  max-width: 1300px;
  width: 100%;


  .logoWrapper{
    display:flex;

    .itqLogo{
      opacity: 0;
    }
  }


  .smallSep{
    display:block;
    width:1px;
    height: 68px;
    background:#666666;
    margin-left: 50px;
    margin-right: 50px;
    opacity:0;
  }

  .itqQuoteWrapper{
    display: flex;
    flex-direction: column;
  }

  .itqQuote{
    color:#000;
    font-family: 'Stem-Light';
    font-style: italic;
    opacity: 0;      
    font-weight: 300;  
    text-shadow: 1px 1px 6px #a9a8a8;      
  }

  .UlitqProductsIntro{
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 100%;
    justify-content: start;
    margin-left: 5px;

    li{

      opacity:0;

      &::after{
        content:","
      }

      &:last-of-type::after{
        content:""
      }
    }
  }

}

.fixedHeader{
  justify-self: center;
  align-self: baseline;
  align-items: center;

  padding-top:40px;

}

.setOpacity{
  opacity: 1 !important;
}

//Fadein Left
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

.animatedFadeInLeft {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/*End of FadeInLeft */

/* Fade down */
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.animatedFadeInDown {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
/*End of Fade Down*/

/*Fade in Up*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.animatedFadeInUp {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
/*End of Fade in Up*/



/*Fade in */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.animatedFadeIn {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
/*End of Fade in*/

/*Ul List fade in one by one*/


.UlitqProductsIntroFade{

  li {
    opacity: 0;
    -webkit-animation: fadeIn 0.9s 1;
    animation: fadeIn 0.9s 1;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  li:nth-child(7n+1) {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  
  li:nth-child(7n+2) {
    -webkit-animation-delay: 1.0s;
    animation-delay: 1.0s;
  }
  
  li:nth-child(7n+3) {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
  }
  
  li:nth-child(7n+4) {
    -webkit-animation-delay: 2.0s;
    animation-delay: 2.0s;
  }
  
  li:nth-child(7n+5) {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
  }
  
  li:nth-child(7n+6) {
    -webkit-animation-delay: 3.0s;
    animation-delay: 3.0s;
  }

  li:nth-child(7n+7) {
    -webkit-animation-delay: 3.5s;
    animation-delay: 3.5s;
  }
  
  
  
  
  
  /* Animation steps */
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0.0;
    }
    100% {
      opacity: 1.0;
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0.0;
    }
    100% {
      opacity: 1.0;
    }
  }  

}

/* End of ul li*/

/* Starts of row to up*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: visible;
    -webkit-transform: translate3d(0, -1500%, 0);
    transform: translate3d(0, -1500%, 0);
  }
}

@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: visible;
    -webkit-transform: translate3d(0, -1500%, 0);
    transform: translate3d(0, -1500%, 0);
  }
}

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

.animatedslideOutUp {
  -webkit-animation-duration: 2.2s;
  animation-duration: 2.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
/* Ends of row to up*/

/* Slide out mobile */
  /* Starts of row to up*/
  @-webkit-keyframes slideOutUpMobile {
    from {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    to {
      visibility: visible;
      -webkit-transform: translate3d(0, -50%, 0);
      transform: translate3d(0, -50%, 0);
    }
  }

  @keyframes slideOutUpMobile {
    from {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    to {
      visibility: visible;
      -webkit-transform: translate3d(0, -50%, 0);
      transform: translate3d(0, -50%, 0);
    }
  }

  .slideOutUpMobile {
    -webkit-animation-name: slideOutUp;
    animation-name: slideOutUp;
  }

  .animatedslideOutUpMobile {
    -webkit-animation-duration: 6s;
    animation-duration: 6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  /* Ends of row to up*/
/* Ends of slide out*/


.itqContentWrapper{
  opacity: 0;
  display: none;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top:40px;
  padding-bottom:60px;
}


.itqContent{
  display:grid;
  grid-template-columns: 302px 101px 1fr;
  justify-self: center;
  align-items: flex-start;
  max-width: 1300px;
  width: 100%;


  .itqContentTitle{
    display:flex;
    justify-content: flex-end;

    h2{
      font-weight: lighter;
      font-size: 27px;
      font-style: normal;
      color:#868686;
    }
  }


  .smallSep{
    display:block;
    width:1px;
    height: 100%;
    background:#666666;
    margin-left: 50px;
    margin-right: 50px;
  }

  .itqContentDetails{
    display: flex;
    flex-direction: column;
    padding-bottom:20px;

    h2{
      font-weight: lighter;
      font-size: 21px;
      font-style: italic;
      color:#868686;
    }    
  }

  .itqContentText{
    color:#010101;
    font-family: 'Stem-Light';
    font-style: normal;
    font-weight: 300; 
    font-size: 18px;
       
  }
}

.itqFooter{
  display:none;
  bottom:0px;
  width: 100%;
  height: 194px;
  background:#000000;
  justify-content: center;
  align-items: flex-start;
  padding-top:40px;
  color:#b2b2b2;
  opacity: 0;

  .itqFooterWrapper{
    display:grid;
    grid-template-columns: 302px 101px 1fr;
    justify-self: center;
    align-items: flex-start;
    max-width: 1300px;
    width: 100%;

    .footerColumnA{
      display:flex;
      align-items: center;
      justify-content: flex-end;

      img{
        height: 40px;
        margin-right: 10px;
      }

      p{
        font-family: 'Stem-Light';
        font-weight: lighter;
        text-align: right;
        font-size:15px;

        
  
      }
    }

    .footerColumnC{

      display: flex;
      flex-direction: column;
      justify-content: center;


      p{
        font-family: 'Stem-Light';
        font-weight: lighter;
        text-align: left;
        font-size:15px;

        &:first-of-type{
          margin-top:8px;
        }

      }

      .storeIconsWrapper{
        margin-top:15px;
      }

    }
    
  }

}

.displayFlex{
  display: flex !important;
}

.mainHeader{
  padding-top:90px;
}

.headerFixed{
  position:fixed;
  width: 100%;
  
}



@media only screen and (max-width: 960px) {

  main{
    position: relative;
    width: 100vw !important;
    
  }

  .itqEntrance{
    position: relative;
    max-width: 100vw !important;
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
    width: 100vw !important;



    .itqQuote{
      text-align: center;
    }

    .UlitqProductsIntro{
      grid-gap: 5px;
      margin-left:0px;
    }


  }

  .itqContentWrapper{
    position: relative;
    width: 100vw !important;
  }
  
  .itqContent{
    position: relative;
    max-width: 100vw !important;
    display: flex;
    justify-self: center;
    align-items: flex-start;
    width: 100vw !important;
    padding:15px;   
    flex-wrap: wrap;    

    .smallSep{
      display:none !important;
    }

    .itqContentTitle{
      width: 100%;
      justify-content: center;

      h2{
        text-align: center;
      }

    }

    .itqContentDetails{
      position: relative;
      align-items: center;
      margin-top:10px;
      width: 100% !important;


      h2{
        text-align: center;
      }

      .itqContentText{
        text-align: center;
      }
    }

  }
  

  .itqFooter{
    position:relative;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
  }

  .itqFooterWrapper{
    max-width: 100% !important;
    grid-template-columns: 1fr !important;
    grid-template-rows: 1fr 1fr;
    justify-self: center;
    grid-template-areas:'footerColumnC' 
                         'footerColumnA';
  }  

  .footerColumnA{
    grid-area: footerColumnA;
    justify-content:center !important;
    margin-top:20px;
  }

  .footerColumnB{
    grid-area: footerColumnB;
    display:none;
  }

  .footerColumnC{
    grid-area: footerColumnC;
    padding:20px;
    padding-top:0px;

    p{
      text-align: center !important;
    }

    .storeIconsWrapper{
      display:flex;
      justify-content: center;
      padding-top:10px;
    }

  }

    


}

@media only screen and (min-width: 961px) and (max-width: 1366px) {
  .itqEntrance{
    max-width: 1000px !important;
  }
  
  .itqContent{
    max-width: 1000px !important;
  }
  
  .itqFooterWrapper{
    max-width: 1000px !important;
  }  
}

@media only screen and (min-width: 1367px) {
  .itqEntrance{
    max-width: 1300px;
  }
  
  .itqContent{
    max-width: 1300px;
  }
  
  .itqFooterWrapper{
    max-width: 1300px;
  }
}

@media only screen and (max-width: 490px) {
  .itqQuoteWrapper{
    margin-top:25px;
  }
  .UlitqProductsIntro{
    grid-gap: 5px;
    margin-left:0px;
    grid-template-columns: repeat(1, 1fr) !important;
    margin-top:10px;


    li{
      &::after{
        content: "" !important;
      }
    }
  }  

  .itqFooter{
    position:relative;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
  }

  .itqFooterWrapper{
    max-width: 100% !important;
    grid-template-columns: 1fr !important;
    grid-template-rows: 1fr 1fr;
    justify-self: center;
    grid-template-areas:'footerColumnC' 
                         'footerColumnA';
  }  

  .footerColumnA{
    grid-area: footerColumnA;
    justify-content:center !important;
    margin-top:20px;
  }

  .footerColumnB{
    grid-area: footerColumnB;
    display:none;
  }

  .footerColumnC{
    grid-area: footerColumnC;

    p{
      text-align: center !important;
    }

    .storeIconsWrapper{
      display:flex;
      justify-content: center;
      padding-top:10px;
    }

  }  

}

@media only screen and (max-width: 410px) {
  .itqQuoteWrapper{

  }
  .itqQuote{
    font-size:21px
  }
}

.imgResponsive{
  width: 100%;
  height: auto;  
}