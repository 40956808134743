@font-face {
  font-family: 'Stem-Light';
  font-style: normal;
  font-weight: normal;
  src: local('Stem-Light'), url('./assets/fonts/Stem-Light.otf') format('woff');
}

@font-face {
  font-family: 'Stem-Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Stem-Thin'), url('./assets/fonts/Stem-Thin.otf') format('woff');
}

@font-face {
  font-family: 'Stem-LightItalic';
  font-style: italic;
  font-weight: 300;
  src: local('Stem-LightItalic'), url('./assets/fonts/Stem-LightItalic.otf') format('woff');
}
  

  /* @font-face {
  font-family: 'Myriad Pro Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Condensed'), url('./assets/fonts/myriadpro-cond.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Condensed Italic'), url('./assets/fonts/myriadpro-condit.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Light';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Light'), url('./assets/fonts/myriadpro-light.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Semibold'), url('./assets/fonts/myriadpro-semibold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Semibold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Semibold Italic'), url('./assets/fonts/myriadpro-semiboldit.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Bold Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Condensed'), url('./assets/fonts/myriadpro-boldcond.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold'), url('./assets/fonts/myriadpro-bold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Italic'), url('./assets/fonts/myriadpro-boldit.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Bold Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Condensed Italic'), url('./assets/fonts/myriadpro-boldcondit.woff') format('woff');
  } */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
}

body{
  font-family: 'Stem-Light';
}

main{
  display:grid;
  height: 100vh;
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;  
  box-sizing: border-box;
}

.page{
  grid-template-rows: 142px 1fr 194px !important;  
}


::-webkit-scrollbar {
  display: none;
}


.progressBarContainer {
  position: fixed;
  z-index: 10;
  background: rgba(255, 255, 255, 0.45);
}


.progressBar {
  position: absolute;
  will-change: transform, opacity;
  background: linear-gradient(to bottom, lightgrey, #000);
  transform-origin: top center;
  transform: scale(1, 0);
  opacity: 1;
}

.progressBarContainer,
.progressBar {
  top: 0;
  right: 0;
  bottom: 0;
  width: 10px;
}

